var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Informasi Produk"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v("Informasi Produk")])])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Produk",
      "label-for": ""
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Ex: Sarung Tangan Medis"
          },
          model: {
            value: _vm.form.nama,
            callback: function ($$v) {
              _vm.$set(_vm.form, "nama", $$v);
            },
            expression: "form.nama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Produk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "product_type",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 'barang',
              text: 'Barang'
            }, {
              value: 'jasa',
              text: 'Jasa'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.product_type,
            callback: function ($$v) {
              _vm.$set(_vm.form, "product_type", $$v);
            },
            expression: "form.product_type"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kategori",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', [_c('v-select', {
          attrs: {
            "options": _vm.kategoriOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text",
            "placeholder": "Silakan Pilih"
          },
          model: {
            value: _vm.form.id_kategori,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_kategori", $$v);
            },
            expression: "form.id_kategori"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              _vm.showModalKategori = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Add Kategori"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitKategori
          }
        }, [_vm._v(" Add ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModalKategori = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalKategori,
      callback: function ($$v) {
        _vm.showModalKategori = $$v;
      },
      expression: "showModalKategori"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "kategori"
    }
  }, [_vm._v("Kategori")]), _c('b-form-input', {
    attrs: {
      "id": "kategori",
      "placeholder": "Nama kategori"
    },
    model: {
      value: _vm.formkategori,
      callback: function ($$v) {
        _vm.formkategori = $$v;
      },
      expression: "formkategori"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Satuan "
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "satuan",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', [_c('v-select', {
          attrs: {
            "options": _vm.satuanOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function (_ref5) {
              var attributes = _ref5.attributes,
                events = _ref5.events;
              return [_c('input', _vm._g(_vm._b({
                staticClass: "vs__search",
                attrs: {
                  "required": !_vm.form.id_satuan
                }
              }, 'input', attributes, false), events))];
            }
          }], null, true),
          model: {
            value: _vm.form.id_satuan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_satuan", $$v);
            },
            expression: "form.id_satuan"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              _vm.showModalSatuan = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Add Satuan"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitSatuan
          }
        }, [_vm._v(" Add ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModalSatuan = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalSatuan,
      callback: function ($$v) {
        _vm.showModalSatuan = $$v;
      },
      expression: "showModalSatuan"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "satuan"
    }
  }, [_vm._v("Satuan")]), _c('b-form-input', {
    attrs: {
      "id": "satuan",
      "placeholder": "Nama satuan"
    },
    model: {
      value: _vm.formsatuan,
      callback: function ($$v) {
        _vm.formsatuan = $$v;
      },
      expression: "formsatuan"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Produk Konsinyasi?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "is_consignment",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 0,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.is_consignment,
            callback: function ($$v) {
              _vm.$set(_vm.form, "is_consignment", $$v);
            },
            expression: "form.is_consignment"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kondisi?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kondisi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Baru'
            }, {
              value: 2,
              text: 'Bekas'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.kondisi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "kondisi", $$v);
            },
            expression: "form.kondisi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Deskripsi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "deskripsi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('quill-editor', {
          attrs: {
            "options": _vm.snowOption
          },
          model: {
            value: _vm.form.deskripsi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "deskripsi", $$v);
            },
            expression: "form.deskripsi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v("Varian Produk")])])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('b-table-simple', {
    staticClass: "table table-bordered",
    attrs: {
      "small": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Kode")]), _c('b-th', [_vm._v("Varian")]), _c('b-th', [_vm._v("Harga Dasar")]), _c('b-th', [_vm._v("No. Batch")]), _c('b-th', [_vm._v("Expired Date")]), _c('b-th', {
    staticStyle: {
      "width": "3%"
    }
  })], 1)], 1), _c('b-tbody', _vm._l(_vm.varianitems, function (item, i) {
    return _c('b-tr', {
      key: i
    }, [_c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Ex: 23213",
        "required": ""
      },
      model: {
        value: item.kode,
        callback: function ($$v) {
          _vm.$set(item, "kode", $$v);
        },
        expression: "item.kode"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Ex: Ukuran XL",
        "required": ""
      },
      model: {
        value: item.varian,
        callback: function ($$v) {
          _vm.$set(item, "varian", $$v);
        },
        expression: "item.varian"
      }
    })], 1), _c('b-td', [_c('b-input-group', {
      staticClass: "input-group-merge",
      attrs: {
        "prepend": "Rp."
      }
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Harga Dasar",
        "required": ""
      },
      on: {
        "keyup": function ($event) {
          return _vm.doFormatRupiah(i);
        }
      },
      model: {
        value: item.harga_dasar,
        callback: function ($$v) {
          _vm.$set(item, "harga_dasar", $$v);
        },
        expression: "item.harga_dasar"
      }
    })], 1)], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "No. Batch",
        "required": ""
      },
      model: {
        value: item.no_batch,
        callback: function ($$v) {
          _vm.$set(item, "no_batch", $$v);
        },
        expression: "item.no_batch"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "type": "date",
        "placeholder": "Expired Date",
        "required": ""
      },
      model: {
        value: item.expire_date,
        callback: function ($$v) {
          _vm.$set(item, "expire_date", $$v);
        },
        expression: "item.expire_date"
      }
    })], 1), _c('b-td', [i == 0 ? _c('section', [_c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.addVar($event);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon"
      }
    })], 1)], 1) : i > 0 ? _c('section', [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.removeVar(i, item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1) : _vm._e()])], 1);
  }), 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Pengiriman (tidak wajib)")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Berat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "berat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var errors = _ref9.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "gram"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "1000"
          },
          model: {
            value: _vm.form.berat,
            callback: function ($$v) {
              _vm.$set(_vm.form, "berat", $$v);
            },
            expression: "form.berat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Panjang"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "panjang",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var errors = _ref10.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "100"
          },
          model: {
            value: _vm.form.panjang,
            callback: function ($$v) {
              _vm.$set(_vm.form, "panjang", $$v);
            },
            expression: "form.panjang"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lebar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lebar",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        var errors = _ref11.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "10"
          },
          model: {
            value: _vm.form.lebar,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lebar", $$v);
            },
            expression: "form.lebar"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tinggi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tinggi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref12) {
        var errors = _ref12.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "10"
          },
          model: {
            value: _vm.form.tinggi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tinggi", $$v);
            },
            expression: "form.tinggi"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Minimal Pesanan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "min_pesanan",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Minimal Pesanan"
          },
          model: {
            value: _vm.form.min_pesanan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "min_pesanan", $$v);
            },
            expression: "form.min_pesanan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asuransi Pengiriman"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asuransi_pengiriman",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Wajib'
            }, {
              value: 2,
              text: 'Opsi'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.asuransi_pengiriman,
            callback: function ($$v) {
              _vm.$set(_vm.form, "asuransi_pengiriman", $$v);
            },
            expression: "form.asuransi_pengiriman"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Layanan Ekspedisi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "layanan_ekspedisi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref15) {
        var errors = _ref15.errors;
        return [_c('v-select', {
          attrs: {
            "multiple": "",
            "options": _vm.layananEkspedisiOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function (_ref16) {
              var attributes = _ref16.attributes,
                events = _ref16.events;
              return [_c('input', _vm._g(_vm._b({
                staticClass: "vs__search",
                attrs: {
                  "required": !_vm.form.layanan_ekspedisi
                }
              }, 'input', attributes, false), events))];
            }
          }], null, true),
          model: {
            value: _vm.form.layanan_ekspedisi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "layanan_ekspedisi", $$v);
            },
            expression: "form.layanan_ekspedisi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Lain-lain")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": _vm.form.is_pre_order == 1 ? '6' : '12',
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Apakah Produk PreOrder?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "is_pre_order",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 0,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.is_pre_order,
            callback: function ($$v) {
              _vm.$set(_vm.form, "is_pre_order", $$v);
            },
            expression: "form.is_pre_order"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.form.is_pre_order == 1 ? _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lama Waktu PreOrder"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pre_order_day",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        var errors = _ref18.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Lama"
          },
          model: {
            value: _vm.form.pre_order_day,
            callback: function ($$v) {
              _vm.$set(_vm.form, "pre_order_day", $$v);
            },
            expression: "form.pre_order_day"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_vm._v("Hari")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2092461598)
  })], 1)], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perlihatkan ke cabang lain?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "show_to_others",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 0,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.show_to_others,
            callback: function ($$v) {
              _vm.$set(_vm.form, "show_to_others", $$v);
            },
            expression: "form.show_to_others"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1)], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Konversi Satuan",
      "active": _vm.$route.query.tab && _vm.$route.query.tab == 'konversi' ? true : false
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.tambah
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Add')) + " ")], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Add"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.saveKonversi
          }
        }, [_vm._v(" Simpan ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Reset ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModal = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "satuan"
    }
  }, [_vm._v("Pilih Satuan yang akan di konversikan")]), _c('b-form-select', {
    attrs: {
      "options": _vm.id_satuan,
      "label": "satuan"
    },
    model: {
      value: _vm.form2.id_satuan,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "id_satuan", $$v);
      },
      expression: "form2.id_satuan"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Isi")]), _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Isi"
    },
    model: {
      value: _vm.form2.nilai,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "nilai", $$v);
      },
      expression: "form2.nilai"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "id": "table2",
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.items,
      "fields": _vm.fields2
    },
    scopedSlots: _vm._u([{
      key: "cell(id_barang)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getBarangName(data.item)) + " ")];
      }
    }, {
      key: "cell(nilai)",
      fn: function (_ref20) {
        var item = _ref20.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.nilai)) + " ")];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref21) {
        var index = _ref21.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Ubah'",
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1), _c('b-button', {
          attrs: {
            "title": "'Hapus'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Pengaturan Harga"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Varian"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "varian",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref22) {
        var errors = _ref22.errors;
        return [_c('b-form-select', {
          on: {
            "change": _vm.cek
          },
          model: {
            value: _vm.form2.varian_id,
            callback: function ($$v) {
              _vm.$set(_vm.form2, "varian_id", $$v);
            },
            expression: "form2.varian_id"
          }
        }, _vm._l(_vm.varianItem, function (option) {
          return _c('option', {
            key: option.barang_id,
            domProps: {
              "value": option.barang_id
            }
          }, [_vm._v(" " + _vm._s(option.text) + " ")]);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Konsumen"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tipe_konsumen",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref23) {
        var errors = _ref23.errors;
        return [_c('b-form-select', {
          on: {
            "change": _vm.cek
          },
          model: {
            value: _vm.form2.tipe_konsumen_id,
            callback: function ($$v) {
              _vm.$set(_vm.form2, "tipe_konsumen_id", $$v);
            },
            expression: "form2.tipe_konsumen_id"
          }
        }, _vm._l(_vm.tipe_konsumen, function (option) {
          return _c('option', {
            key: option.id,
            domProps: {
              "value": option.id
            }
          }, [_vm._v(" " + _vm._s(option.nama) + " ")]);
        }), 0)];
      }
    }])
  })], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loadingPengaturanHarga
    }
  }, [_c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "hover": "",
      "small": "",
      "bordered": "",
      "fields": [
      // { key: 'no', label: 'No' },
      {
        key: 'harga_coret',
        label: 'Harga Coret'
      }, {
        key: 'satuan',
        label: 'Satuan'
      }, {
        key: 'share_jasa',
        label: 'Payout'
      }, {
        key: 'share_penjual',
        label: 'Harga Jual Mitra'
      }, {
        key: 'harga_jual',
        label: 'Harga Jual'
      }, {
        key: 'harga_dasar',
        label: 'Harga Dasar'
      }, {
        key: 'margin2',
        label: 'Laba Seller'
      }],
      "items": _vm.konversiSatuanItem
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref24) {
        var index = _ref24.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(share_jasa)",
      fn: function (_ref25) {
        var item = _ref25.item,
          index = _ref25.index;
        return [_vm.isFinance ? [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Mark Up"
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            }
          },
          model: {
            value: item.share_jasa,
            callback: function ($$v) {
              _vm.$set(item, "share_jasa", $$v);
            },
            expression: "item.share_jasa"
          }
        })] : [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Mark Up",
            "readonly": ""
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            }
          },
          model: {
            value: item.share_jasa,
            callback: function ($$v) {
              _vm.$set(item, "share_jasa", $$v);
            },
            expression: "item.share_jasa"
          }
        })]];
      }
    }, {
      key: "cell(share_penjual)",
      fn: function (_ref26) {
        var item = _ref26.item,
          index = _ref26.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Harga Beli Penjual"
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            }
          },
          model: {
            value: item.share_penjual,
            callback: function ($$v) {
              _vm.$set(item, "share_penjual", $$v);
            },
            expression: "item.share_penjual"
          }
        })];
      }
    }, {
      key: "cell(harga_coret)",
      fn: function (_ref27) {
        var item = _ref27.item,
          index = _ref27.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Harga Coret"
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            }
          },
          model: {
            value: item.harga_coret,
            callback: function ($$v) {
              _vm.$set(item, "harga_coret", $$v);
            },
            expression: "item.harga_coret"
          }
        })];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref28) {
        var item = _ref28.item,
          index = _ref28.index;
        return [_vm._v(" " + _vm._s(item.harga_dasar) + " ")];
      }
    }, {
      key: "cell(margin)",
      fn: function (_ref29) {
        var item = _ref29.item,
          index = _ref29.index;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga_jual - item.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(margin2)",
      fn: function (_ref30) {
        var item = _ref30.item;
        return [_vm._v(" " + _vm._s(_vm.unFormatRupiah(item.share_penjual) - _vm.unFormatRupiah(item.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function (_ref31) {
        var item = _ref31.item,
          index = _ref31.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "text",
            "readonly": "",
            "placeholder": "Harga Jual"
          },
          model: {
            value: item.harga_jual,
            callback: function ($$v) {
              _vm.$set(item, "harga_jual", $$v);
            },
            expression: "item.harga_jual"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.saveHarga($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Foto Produk"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingFotoBarang
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "table-responsive"
  }, [_c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "hover": "",
      "bordered": "",
      "fields": [{
        key: 'varian',
        label: 'Varian Utama'
      }, {
        key: 'photo',
        label: 'Foto'
      }, {
        key: 'aksi',
        label: 'Aksi'
      }],
      "items": _vm.varianFotoUtama
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref32) {
        var index = _ref32.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(photo)",
      fn: function (_ref33) {
        var item = _ref33.item,
          index = _ref33.index;
        return [item.path ? _c('img', {
          staticClass: "d-block mb-1",
          staticStyle: {
            "width": "70px"
          },
          attrs: {
            "src": item.path,
            "alt": ""
          }
        }) : _vm._e(), _c('input', {
          ref: "file_foto",
          attrs: {
            "type": "file"
          },
          on: {
            "change": function ($event) {
              return _vm.onSelectedPhotoUtama($event, index);
            }
          }
        })];
      }
    }, {
      key: "cell(aksi)",
      fn: function (_ref34) {
        var item = _ref34.item,
          index = _ref34.index;
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.savePhoto(item);
            }
          }
        }, [_vm._v("Simpan")])];
      }
    }])
  })], 1)]), _vm.varianItemOnly.length > 0 ? _c('b-card', [_c('div', {
    staticClass: "table-responsive"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Varian"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "varian",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref35) {
        var errors = _ref35.errors;
        return [_c('v-select', {
          attrs: {
            "options": _vm.varianItemOnly,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.foto_varian_id,
            callback: function ($$v) {
              _vm.foto_varian_id = $$v;
            },
            expression: "foto_varian_id"
          }
        })];
      }
    }], null, false, 3064920995)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Foto"
    }
  }, [_c('input', {
    ref: "file_foto",
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": function ($event) {
        return _vm.onSelectedPhotoPendukungs($event, _vm.index);
      }
    }
  }), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.savePhotos();
      }
    }
  }, [_vm._v("Add")])], 1), _vm.fotoBarangPendukung.length > 0 ? _c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "hover": "",
      "bordered": "",
      "fields": [{
        key: 'no',
        label: 'No'
      }, {
        key: 'photo',
        label: 'Foto'
      }, {
        key: 'aksi',
        label: 'Aksi'
      }],
      "items": _vm.fotoBarangPendukung
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref36) {
        var index = _ref36.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(photo)",
      fn: function (_ref37) {
        var item = _ref37.item,
          index = _ref37.index;
        return [item.path ? _c('img', {
          staticClass: "d-block mb-1",
          staticStyle: {
            "width": "70px"
          },
          attrs: {
            "src": item.path,
            "alt": ""
          }
        }) : _vm._e(), _c('input', {
          ref: "file_foto",
          attrs: {
            "type": "file"
          },
          on: {
            "change": function ($event) {
              return _vm.onSelectedPhotoPendukung($event, index);
            }
          }
        })];
      }
    }, {
      key: "cell(aksi)",
      fn: function (_ref38) {
        var item = _ref38.item,
          index = _ref38.index;
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.savePhoto(item);
            }
          }
        }, [_vm._v("Simpan")]), _c('b-button', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.hapusFoto(item);
            }
          }
        }, [_vm._v("Hapus")])];
      }
    }], null, false, 2135032280)
  }) : _vm._e()], 1)]) : _vm._e()], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Penyimpanan"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Buat Penyimpanan Baru untuk barang ini?"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "2"
    },
    model: {
      value: _vm.mode,
      callback: function ($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Buat Penyimpanan baru")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "1"
    },
    model: {
      value: _vm.mode,
      callback: function ($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_vm._v("Pilih penyimpanan yang sudah ada")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref39) {
        var errors = _ref39.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Varian")]), _c('v-select', {
          attrs: {
            "options": _vm.varianItem,
            "label": "text"
          },
          on: {
            "change": function ($event) {
              return _vm.getStockBarang();
            }
          },
          model: {
            value: _vm.form.varian,
            callback: function ($$v) {
              _vm.$set(_vm.form, "varian", $$v);
            },
            expression: "form.varian"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_vm.mode == 2 ? _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Buat Penyimpanan Baru",
      "border-variant": "primary"
    }
  }, [_c('validation-observer', {
    ref: "form2"
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref40) {
        var errors = _ref40.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('b-form-select', {
          attrs: {
            "options": _vm.blok_id,
            "required": !_vm.form.blok_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          on: {
            "change": function ($event) {
              return _vm.getpalet();
            }
          },
          model: {
            value: _vm.form.blok_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "blok_id", $$v);
            },
            expression: "form.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2612816540)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Pallet")]), _c('b-form-select', {
    attrs: {
      "options": _vm.palet_id,
      "required": !_vm.form.palet_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    on: {
      "change": function ($event) {
        return _vm.getRak();
      }
    },
    model: {
      value: _vm.form.palet_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "palet_id", $$v);
      },
      expression: "form.palet_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.rak_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    on: {
      "change": function ($event) {
        return _vm.getLaci();
      }
    },
    model: {
      value: _vm.form.rak_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rak_id", $$v);
      },
      expression: "form.rak_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.laci_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.laci_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "laci_id", $$v);
      },
      expression: "form.laci_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Add")])], 1)], 1)], 1)], 1)])], 1)], 1) : _vm._e(), _vm.mode == 2 ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Daftar Penyimpanan Baru yang akan disimpan",
      "border-variant": "primary"
    }
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fieldsAddedItems,
      "bordered": "",
      "striped": "",
      "responsive": "",
      "items": _vm.addedItems
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(lokasi)",
      fn: function (_ref41) {
        var item = _ref41.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.lokasi) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('b-link', {
          staticClass: "text-danger",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.addedItems.splice(row.index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }], null, false, 3299828433)
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitPenyimpanan($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1) : _vm._e(), _vm.mode == 1 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_vm.items.length > 0 ? _c('b-card', {
    attrs: {
      "border-variant": "primary",
      "title": "Pilih Penyimpanan Produk"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fieldsasli
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function (data) {
        return [_c('b-tr', [_c('b-th', {
          staticStyle: {
            "background-color": "transparent"
          },
          attrs: {
            "colspan": "6"
          }
        }), _c('b-th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Lokasi Penyimpanan")])], 1)];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref42) {
        var item = _ref42.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-") + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref43) {
        var item = _ref43.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")];
      }
    }, {
      key: "cell(varian)",
      fn: function (_ref44) {
        var item = _ref44.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-") + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref45) {
        var item = _ref45.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : "-") + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function (_ref46) {
        var item = _ref46.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : "-") + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref47) {
        var item = _ref47.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : "-") + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref48) {
        var item = _ref48.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : "-") + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref49) {
        var item = _ref49.item;
        return [_vm._v(" " + _vm._s(item.jumlah ? item.jumlah : "-") + " "), _c('span')];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref50) {
        var item = _ref50.item;
        return [_vm._v(" " + _vm._s(item.konversi_ket) + " ")];
      }
    }, {
      key: "cell(selesai)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.status_ket ? data.item.status_ket.toUpperCase() : "-") + " ")])];
      }
    }, {
      key: "cell(po)",
      fn: function (_ref51) {
        var item = _ref51.item;
        return [_vm._v(" " + _vm._s(item.po ? item.po.no : "po kosong") + " ")];
      }
    }, {
      key: "cell(tanggal_pengiriman)",
      fn: function (_ref52) {
        var item = _ref52.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(tanggal_invoice)",
      fn: function (_ref53) {
        var item = _ref53.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal_invoice)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref54) {
        var item = _ref54.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Detail'",
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(("/owner/pengiriman/detail/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), _vm.allowUpdate() && item.status == 1 || _vm.allowUpdate() && !_vm.isAdminGudang ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Batalkan Pengiriman'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.batal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        })], 1) : _vm._e(), _vm.allowUpdate() && item.status == 0 || _vm.allowUpdate() && !_vm.isAdminGudang ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Ubah'",
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(("/owner/pengiriman/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() && item.status == 0 || _vm.allowUpdate() && !_vm.isAdminGudang ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Hapus'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "cell(actionsCabang)",
      fn: function (_ref55) {
        var item = _ref55.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_c('b-button', {
          attrs: {
            "title": "'Ubah Lokasi Penyimpanan'",
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.ubahpeny(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "Edit3Icon"
          }
        })], 1)], 1)];
      }
    }], null, false, 1204740147)
  })], 1)], 1)], 1) : _vm._e(), _vm.items.length == 0 ? _c('b-card', {
    staticClass: "text-center",
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" Produk tersebut belum diatur penyimpanannya ")])]) : _vm._e()], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": 'Ubah Lokasi Penyimpanan',
      "size": "lg"
    },
    model: {
      value: _vm.showModalPen,
      callback: function ($$v) {
        _vm.showModalPen = $$v;
      },
      expression: "showModalPen"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm._v(" " + _vm._s(this.ubahpen ? this.ubahpen.barang ? this.ubahpen.barang.nama : 0 : 0) + " "), _c('b-card', {
    attrs: {
      "title": "Buat Penyimpanan Baru",
      "border-variant": "primary"
    }
  }, [_c('validation-observer', {
    ref: "form2"
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref56) {
        var errors = _ref56.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('b-form-select', {
          attrs: {
            "options": _vm.blok_id,
            "required": !_vm.ubahpen.blok_id,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          on: {
            "change": function ($event) {
              return _vm.getpalet();
            }
          },
          model: {
            value: _vm.ubahpen.blok_id,
            callback: function ($$v) {
              _vm.$set(_vm.ubahpen, "blok_id", $$v);
            },
            expression: "ubahpen.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Palet")]), _c('b-form-select', {
    attrs: {
      "options": _vm.palet_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    on: {
      "change": function ($event) {
        return _vm.getRak();
      }
    },
    model: {
      value: _vm.ubahpen.palet_id,
      callback: function ($$v) {
        _vm.$set(_vm.ubahpen, "palet_id", $$v);
      },
      expression: "ubahpen.palet_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Please select an option")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.rak_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    on: {
      "change": function ($event) {
        return _vm.getLaci();
      }
    },
    model: {
      value: _vm.ubahpen.rak_id,
      callback: function ($$v) {
        _vm.$set(_vm.ubahpen, "rak_id", $$v);
      },
      expression: "ubahpen.rak_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.laci_id,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.ubahpen.laci_id,
      callback: function ($$v) {
        _vm.$set(_vm.ubahpen, "laci_id", $$v);
      },
      expression: "ubahpen.laci_id"
    }
  })], 1)], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitpen($event);
      }
    }
  }, [_vm._v(" Ubah Lokasi Penyimpanan ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }